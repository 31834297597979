/** @format */

@font-face {
  font-family: "Trebuchet MS";
  src: url("./font/Trebuchet-MS-Italic.ttf") format("truetype");
}
/////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR //////////////////////////
body {
  user-select: none;
  -moz-user-select: none;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background-color: rgb(52 196 57) !important;
  padding: 10px !important;
}

.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}

////////////////////// SCROLL COLOR /////////////////////////////

::-webkit-scrollbar {
  width: 5px;

  /*it's not helping with showing the thumb border-left*/
  overflow: auto;
}

::-webkit-scrollbar-track {
  background: transparent;
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  background-position: 50%;
  background-repeat: no-repeat;
  background: #84919d;
  background-size: 7px;
  border-radius: 2px;
  box-shadow: inset -3px 0 #000000, inset 1px 1px #9d9d9d;
}

::-webkit-scrollbar-thumb:hover {
  /*doesnt work (the goal is make it bigger than scrollbar itself)*/
  transform: scale(1.5);
  border-left: 5px #9dbbf3 solid;
}
::-webkit-scrollbar-corner {
  background: #dfdfdf;
}

//////////////////////////////////   Layout ////////////////////////

.main-section {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/////////////////////////// HSidebar SECTION ////////////////////////////////////////

.Sidebar-section {
  ul {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(94px, 1fr));
    grid-template-rows: repeat(4, auto);
    max-width: 320px;

    li {
      list-style: none;
      text-align: center;
      font-family: "Trebuchet MS";
      font-weight: 500;
      color: #fff !important;
      font-size: 14px;
      padding: 5px 0px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 4px;
      text-shadow: 0 2px 4px rgba(44, 43, 43, 0.5);
      margin-bottom: 10px;

      &:hover {
        background-color: rgba(28, 28, 28, 0.5);
        color: #fff;
      }

      a {
        text-decoration: none;
        color: #fff;

        img {
          width: 100%;
          max-width: 54px;
          object-fit: contain;
        }

        p {
          margin-top: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

//////////////////////////// HERO SECTION ///////////////////////////////////
.layout-section {
  min-height: 100vh !important;
  background-image: url(../public/assets/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: 400;
}

.Hero-section {
  .position-relative {
    height: 88vh;
    width: 65vw;
  }

  .recycle-bin {
    position: absolute;
    bottom: -25px;
    right: -150px;
    text-decoration: none;
    font-family: "Trebuchet MS";
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 4px;
    text-shadow: 0 2px 4px rgba(44, 43, 43, 0.5);

    &:hover {
      background-color: rgba(28, 28, 28, 0.5);
    }
  }
}

//////////////////////////////////////////     Footer Section   //////////////////////////////////////////////

.FooterMenu-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    180deg,
    hsl(206, 15%, 21%),
    hsl(0, 0%, 0%) 8%,
    hsl(220, 14%, 8%) 40%,
    hsl(206, 15%, 21%) 88%,
    hsl(206, 15%, 21%) 93%,
    hsl(206, 15%, 21%) 95%,
    hsl(223, 10%, 36%) 96%,
    hsl(222, 13%, 26%)
  );
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .start-strip {
    width: 1.8rem;
    height: auto;
    background-color: #eca6a6;
    align-items: flex-end;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    padding-left: 4px;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;

    .text-block {
      text-align: left;
      object-fit: fill;
      display: block;
      position: relative;
      top: -11px;
      left: -12px;
      transform: translate(0, -10px) rotate(-90deg);
    }
  }

  .menu-side-wrapper {
    font-family: VT323, sans-serif;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;

    .drop-up-menu {
      position: absolute;
      bottom: 42px;
      border-radius: 8px 8px 0px 0px;
      width: auto;
      min-height: 3rem;
      background-color: #000000;
      // padding: 1rem 1rem 1rem 3rem;
      box-shadow: inset 3px 2px #4a4a4a, 2px 2px #000000;

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        vertical-align: middle;
      }
      p {
        margin-bottom: 0px;
        vertical-align: middle;
        margin-right: 10px;
        font-family: Trebuchet MS;
        font-size: 13px;
        color: #fff;
      }
      ul li {
        list-style: none;
        padding: 6px 10px;
        margin: 10px 20px;
        border-radius: 5px;
        color: #fff;

        &:hover {
          background-color: #3f3f3f;
          color: #fff;
        }

        a {
          text-decoration: none;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .header-start {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border-radius: 8px 8px 0px 0px;
        padding: 10px 20px;
        border-bottom: 1px solid #cccccc;
        background: linear-gradient(
          180deg,
          hsl(0, 0%, 24%),
          hsl(0, 0%, 0%) 8%,
          hsl(0, 0%, 0%) 40%,
          hsl(210, 1%, 35%) 88%,
          hsl(210, 1%, 35%) 93%,
          hsl(210, 1%, 35%) 95%,
          hsl(0, 0%, 40%) 96%,
          hsl(0, 0%, 40%)
        );
        h6 {
          margin-bottom: 0px;
          color: white;
          font-weight: 600;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 8px;
          border: 2px solid white;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      .footer-start {
        padding: 16px 20px 16px 20px;
        border-top: 1px solid #cccccc;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .timer-div {
    width: 140px;
    height: 40px;
    background: linear-gradient(
      180deg,
      hsl(0, 0%, 24%),
      hsl(0, 0%, 0%) 8%,
      hsl(0, 0%, 0%) 40%,
      hsl(210, 1%, 35%) 88%,
      hsl(210, 1%, 35%) 93%,
      hsl(210, 1%, 35%) 95%,
      hsl(0, 0%, 40%) 96%,
      hsl(0, 0%, 40%)
    );
    margin-left: auto;
    border-left: 2px solid #fdfeff;
    font-family: "Trebuchet MS", sans-serif;
    font-weight: 500;
    color: #fff;
    font-size: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

////////////////////////

.modal-content {
  border-radius: 0px !important;
  background-color: #030303 !important;
  box-shadow: none !important;

  .navtab-header {
    background-color: #030303;
    border-bottom: 3px solid #d6dbdb;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #030303;
    color: #fff;
  }
  .nav-link {
    color: #fff;
  }
}

.popup-button,
.btn-close {
  border-radius: 3px;
  border: 1px solid #fff;
}
.btn-ok {
  font-size: 11px !important;
  border: 1px solid #003c74 !important;
  background: linear-gradient(180deg, #fff, #ecebe5 86%, #d8d0c4);
  box-shadow: none;
  border-radius: 3px !important;
}

.modal-content {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.title-bar {
  font-family: "Trebuchet MS", sans-serif;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(
    180deg,
    hsl(0, 0%, 24%),
    hsl(0, 0%, 0%) 8%,
    hsl(0, 0%, 0%) 40%,
    hsl(210, 1%, 35%) 88%,
    hsl(210, 1%, 35%) 93%,
    hsl(210, 1%, 35%) 95%,
    hsl(0, 0%, 40%) 96%,
    hsl(0, 0%, 40%)
  );
  padding: 3px 5px 3px 3px;
  border-top: 1px solid #fff;
  border-left: 1px solid #ffff;
  border-right: 1px solid #757575;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 13px;
  text-shadow: 1px 1px #424242;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.RecycleBin-modal-section {
  .items {
    list-style: none;
    text-align: center;
    font-family: "Trebuchet MS";
    font-weight: 500;
    color: black;
    font-size: 14px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 4px;
    text-shadow: 0 2px 4px rgba(44, 43, 43, 0.5);

    &:hover {
      background-color: rgba(92, 84, 84, 0.649);
    }

    a {
      text-decoration: none;
      color: #fff;

      img {
        width: 100%;
        max-width: 54px;
        object-fit: contain;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0px;
        width: 70px;
      }
    }
  }
}

/////////////////  Media Query /////////

@media only screen and (max-width: 600px) {
  .Sidebar-section ul li a h3 {
    font-size: 1rem;
  }
  .Sidebar-section ul li a img {
    width: 3rem;
  }
  .FooterMenu-section .menu-side-wrapper .menu-button {
    width: 5rem;
  }
  .FooterMenu-section .menu-side-wrapper {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
  }
  .FooterMenu-section .timer-div {
    margin-right: 6px;
  }
}
